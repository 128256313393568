import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose } from '@fortawesome/pro-light-svg-icons'

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  hidden: true };
    }

    change = () =>{
        this.setState({hidden: !this.state.hidden});
        //document.getElementById('___gatsby').
        if( this.state.hidden === true ) {
            document.getElementsByTagName("BODY")[0].classList.add('modal-open');
        }

    }
    onClick = e => {
        this.setState({hidden: !this.state.hidden});
        if( this.state.hidden !== true ) {

            document.getElementsByTagName("BODY")[0].classList.remove('modal-open');
        }

    }
  render() {
    return (
    <div hidden={this.state.hidden} className="modal">
        <div className="modal-body">
            <div id="close" onClick={this.onClick} onKeyDown={this.onClick} role="button" tabindex="0"><FontAwesomeIcon className="close" icon={faWindowClose} /></div>
            <div id="modal-content">
                <div dangerouslySetInnerHTML={{__html: this.props.terms}}></div>
            </div>
        </div>
    </div>
    )
  }
}
export default Modal
